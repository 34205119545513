import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/home/pages/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    // Set metadata for component in this object
    data: {
      title: 'KissMyButton Web Development Academy',
      description:
        'Το KissMyButton Web Development Academy είναι το σεμινάριο που έψαχνες για λάβεις την απαραίτητη επαγγελματική εκπαίδευση που χρειάζεται ένας νέος Web Developer',
      // robots : ''
    },
    pathMatch: 'full',
  },
  {
    path: 'junior-front-end-developer',
    data: {
      title: 'Junior Front End Developer',
      description:
        'Πρόγραμμα εκπαίδευσης Junior Front End',
      // robots : ''
    },
    loadChildren: () =>
      import('./modules/junior-frontend/junior-frontend.module').then(
        (m) => m.JuniorFrontendModule
      ),
  },
  {
      path: 'angular-framework',
      data: {
        title: 'Angular Framework',
        description:
          '',
        // robots : ''
      },
      loadChildren: () =>
        import('./modules/angular-framework/angular-framework.module').then(
          (m) => m.AngularFrameworkModule
        ),
    },
  // {
  //     path: 'ai',
  //     data: {
  //       title: 'Artificial Inteligence',
  //       description:
  //         '',
  //       // robots : ''
  //     },
  //     loadChildren: () =>
  //       import('./modules/ai/ai.module').then(
  //         (m) => m.AIModule
  //       ),
  //   },
  // {
  //   path: 'qa-engineer',
  //   data: {
  //     title: 'Qa Engineer',
  //     description:
  //       '',
  //     // robots : ''
  //   },
  //   loadChildren: () =>
  //     import('./modules/qa-engineer/qa-engineer.module').then(
  //       (m) => m.QaEngineerModule
  //     ),
  // },
  // {
  //   path: 'test-automation-engineer',
  //   data: {
  //     title: 'Qa Engineer',
  //     description:
  //       '',
  //     // robots : ''
  //   },
  //   loadChildren: () =>
  //     import('./modules/test-automation-engineer/test-automation-engineer.module').then(
  //       (m) => m.TestAutomationEngineerModule
  //     ),
  // },
  {
    path: 'methodology',
    data: {
      title: 'Μεθοδολογία Εκπαίδευσης Web Developer',
      description:
        'Η Μεθοδολογία Εκπαίδευσης Web Developer του σεμιναρίου είναι η εξής : online μαθήματα, επαφή με Senior Web Developers , εκμάθηση framework και γράφεις κώδικα!',
      // robots : ''
    },
    loadChildren: () =>
      import('./modules/methodology/methodology.module').then(
        (m) => m.MethodologyModule
      ),
  },
  {
    path: 'life-choice',
    data: {
      title: 'Επιλογή ζωής Web Developer',
      description:
        'Tο Web Development Academy της KissMyButton αποσκοπεί στη δημιουργία δυνατών υποψηφίων για την αγορά εργασίας με δυνατότητα για στελέχωση της εταιρείας μας.',
      // robots : ''
    },
    loadChildren: () =>
      import('./modules/life-choice/life-choice.module').then(
        (m) => m.LifeChoiceModule
      ),
  },
  {
    path: 'contact',
    data: {
      title: 'Επικοινωνία με τον καθηγητή του Web Development Bootcamp',
      description:
        'Εδώ θα βρείς τις πληροφορίες του καθηγητή του Web Development Bootcamp της KissMyButton',
      // robots : ''
    },
    loadChildren: () =>
      import('./modules/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('./modules/terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'subscribe-confirmation',
    loadChildren: () =>
      import(
        './modules/subscribe-confirmation/subscribe-confirmation.module'
      ).then((m) => m.SubscribeConfirmationModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
