<section class="flex-container container">
  <div class="img-container" [style.order]="imageOrder">
    <picture>
      <source type="image/webp"
      media="(min-width: 768px) and (max-width: 1139px)"
      [srcset]="section.webpImage320Path || section.webpImage230Path">
      <source
      media="(min-width: 768px) and (max-width: 1139px)" [srcset]="section.image320Path || section.image230Path">
      <source type="image/webp"
      media="(min-width: 1140px)"
      [srcset]="section.webpImage450Path || section.webpImage320Path">
      <source
      media="(min-width: 1140px)" [srcset]="section.image320Path || section.image450Path">
      <img [src]="section.image320Path || section.image230Path" class="responsive-img" [width]="isStudyFromEverywhere? '230': '320' " [height]="isStudyFromEverywhere?'345': (imageOrder === 1 ? '146' : '182')" style="width: initial; height: initial;" alt="Studying"/>
    </picture>
  </div>
  <section class="content">
    <h2>{{ section.title }}</h2>
    <p [innerHTML]="section.content"></p>
  </section>
</section>
