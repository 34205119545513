import { Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener } from '@angular/core';
import { ApplyModalService } from '@shared/services/apply-modal/apply-modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public showHiddenNav = false;
  public showFrontEndHiddenNav = false;
  public showBurgerMenu = false;
  @HostListener('window:resize')
  onResize() {
    this.showHiddenNav = false;
    this.showBurgerMenu = false;
    this.showFrontEndHiddenNav = false;
    // window.location.reload()
  }

  constructor(public applyModalService: ApplyModalService) {}

  public handleClickOutside(e: any) {
    this.showBurgerMenu = e?  false : true;
  }

  public handleClickOutsideCourse(e: any) {
    this.showHiddenNav = e?  false : true;
  }

  public handleClickOutsideFrontEnd(e: any) {
    this.showFrontEndHiddenNav = !this.showFrontEndHiddenNav
  }

  public reloadPageOnBreakpoints(e: any) {
    const width = window.innerWidth;
  }

 
}
