export interface Graduate {
  name: string;
  pngImagePath: string;
  webpImagePath: string;
  comments: string;
  framework: string;
  date: string;
  // company: string;
  // framework?: string;
}

export const GRADUATES: Graduate[] = [
  {
    name: 'Παναγιώτης Μπεζάς',
    pngImagePath: 'assets/images/home/panagiotis_mpezas.jpg',
    webpImagePath: 'assets/images/home/panagiotis_mpezas.webp',
    comments:
      'Όταν αποφάσισα, χωρίς προηγούμενη εμπειρία, να ασχοληθώ με το web development, έψαχνα ένα πρόγραμμα σπουδών σοβαρό και περιεκτικό που να στελεχώνεται από έμπειρους επαγγελματίες του χώρου με όρεξη και υπομονή. Όλα αυτά τα χαρακτηριστικά βρήκα στην KissMyButtonAcademy και τα εφόδια που απέκτησα, αναμφισβήτητα, μου άνοιξαν νέους επαγγελματικούς ορίζοντες.',
    framework: 'Front End Engineer',
    date:"Ιούνιος 2023"
  },
  {
    name: 'Δημήτρης Ιωαννίδης',
    pngImagePath: 'assets/images/home/dimitris_ioannidis.jpg',
    webpImagePath: 'assets/images/home/dimitris_ioannidis.webp',
    comments:
      'Η @kissAcademy μέσα από την ποιότητα εκπαίδευσης και τις πρακτικές εφαρμογές μου παρείχε τις απαραίτητες δεξιότητες για να ξεκινήσω την καριέρα μου ως junior developer.',
    framework: 'Front End Engineer',
    date:"Ιούνιος 2023"
  },
  {
    name: 'Γεωργία Πασόη',
    pngImagePath: 'assets/images/home/georgia_pasoi.jpg',
    webpImagePath: 'assets/images/home/georgia_pasoi.webp',
    comments:
      ' Η φοίτηση στην @KissAcademy είναι ένα ταξίδι συνεχούς μάθησης και εκπαίδευσης προσομοιώνοντας πραγματικές ομάδες ανάπτυξης λογισμικού. Η καλύτερη επιλογή για να αποκτήσεις τις απαραίτητες βάσεις, αλλά και ένα σημαντικό ανταγωνιστικό πλεονέκτημα στην αγορά εργασίας.',
    framework: 'Front End Engineer',
    date:"Ιούνιος 2023"
  },
  {
    name: 'Κωνσταντίνος Παύλος',
    pngImagePath: 'assets/images/home/konstantinos_pavlos.jpg',
    webpImagePath: 'assets/images/home/konstantinos_pavlos.webp',
    comments:
      'Εξαιρετική καθοδήγηση σε ένα χώρο με τεράστια ποσότητα πληροφοριών. Απαντήσεις σε κάθε απορία. Χτίσιμο νοοτροπίας και προσανατολισμός στο τι γίνεται και πως εξελίσσεται η αγορά εργασίας. Ότι ακριβώς χρειάζεται ένας νέος developer!',
    framework: 'Front End Engineer',
    date:"Ιούνιος 2023"
  },
  {
    name: 'Ευάγγελος Γραβάνης',
    pngImagePath: 'assets/images/home/gravanis-evangelos.jpg',
    webpImagePath: 'assets/images/home/gravanis-evangelos.webp',
    comments:
      'Μέσα από την εκπαίδευση μου στην @KissAcademy έθεσα στιβαρά τα θεμέλια που χρειάζεται ένας νέος web developer για την επαγγελματική του ανάπτυξη και είχα την ευκαιρία να έρθω σε επαφή με πολύ έμπειρους επαγγελματίες του χώρου.',
    framework: 'Front End Engineer',
    date:"Ιούνιος 2022"
  },
  {
    name: 'Κωνσταντίνος Μιχαλόπουλος',
    pngImagePath: 'assets/images/home/konstantinos_michalopoulos.jpg',
    webpImagePath: 'assets/images/home/konstantinos_michalopoulos.webp',
    comments:
      'Η εμπειρία και η γνώση που απέκτησα από την πρακτική στην @KissAcademy ήταν κάτι ανεκτίμητο. Εντάχθηκα στον κόσμο του web μαθαίνοντας απο άτομα με πολυ μεγάλη εμπειρία σε τεχνολογίες όπως javascript, typescript και SPA frameworks με πολύ μεθοδική καθοδήγηση και βοήθεια.',
    framework: 'Front End Engineer',
    date:"Ιούνιος 2022"
  },
  
  {
    name: 'Ιβάν Βλάχος',
    pngImagePath: 'assets/images/home/ivan_vlachos.jpg',
    webpImagePath: 'assets/images/home/ivan_vlachos.webp',
    comments:
      'Το μεγαλύτερο όφελος που βίωσα ήταν, ότι σε κάθε απορία μου, είχα πάντα την απάντηση από έναν έμπειρο senior software engineer.',
    framework: 'Front End Engineer',
    date:"Ιούνιος 2022"
  },
  {
    name: 'Ελισάβετ Αμπατζίδου',
    pngImagePath: 'assets/images/home/elisavet_ampatzidou.jpg',
    webpImagePath: 'assets/images/home/elisavet_ampatzidou.webp',
    comments:
      'Οι γνώσεις που απέκτησα στην @KissAcademy με οδήγησαν στην κατάκτηση μιας θέσης εργασίας στην εταιρεία πριν ακόμα λάβω το πτυχίο μου στην Πληροφορική. Αυτό που ξεχωρίζει την @KissAcademy είναι ότι τα μαθήματα γίνονται remote & live από Sr. Engineers που δουλεύουν αυτή την στιγμή στον χώρο και μπορούσαν να μας μεταδώσουν την εμπειρία τους μέσα από πραγματικά projects.',
    framework: 'Front End Engineer',
    date:"Δεκέμβριος 2021"
  },
  {
    name: 'Χρύσα Λαζαρίδου',
    pngImagePath: 'assets/images/home/chrysa_lazaridou.jpg',
    webpImagePath: 'assets/images/home/chrysa_lazaridou.webp',
    comments:
      'Όλοι μας οι υπεύθυνοι ήταν πολύ υποστηρικτικοί δίνοντας μας πλούσιο υλικό και σωστή καθοδήγηση ώστε να εξοικειωθούμε με τις απαιτήσεις του προγραμματισμού σε ένα πραγματικό project.',
    framework: 'Front End Engineer',
    date:"Ιούνιος 2021"
  },
  {
    name: 'Γιώργος Δουδουλιάκης',
    pngImagePath: 'assets/images/home/giorgos_doudouliakis.jpg',
    webpImagePath: 'assets/images/home/giorgos_doudouliakis.webp',
    comments:
      'Η εκπαίδευση στην @KissAcademy μου έδωσε την ευκαιρία να μάθω από ειδικούς του χώρου και να δω από κοντά πως είναι να εργάζεσαι ως software developer.',
    framework: 'Front End Engineer',
    date:"Ιούνιος 2021"
  },
  {
    name: 'Βασίλης Γεωργόπουλος',
    pngImagePath: 'assets/images/home/basilis_georgopoulos.jpg',
    webpImagePath: 'assets/images/home/basilis_georgopoulos.webp',
    comments:
      'Η εκπαίδευσή μου στην @KissAcademy θύμιζε ένα διεξοδικό και συναρπαστικό πανεπιστημιακό μάθημα, με άμεση εφαρμογή του υλικού που καλύφθηκε! Δεν θα μπορούσα να είχα επιλέξει ένα καλύτερο περιβάλλον για την εκπαίδευσή μου ως Junior Developer!',
    framework: 'Full-Stack Engineer',
    date:"Ιούνιος 2019"
  },
  {
    name: 'Μάρκος Αγγέλου',
    pngImagePath: 'assets/images/home/markos_aggelou.jpg',
    webpImagePath: 'assets/images/home/markos_aggelou.webp',
    comments:
      'Η εκπαίδευση μου στην @KissAcademy ήταν αυτή που μου έδωσε την κατάλληλη ώθηση που χρειάζεται ένας νέος προγραμματιστής. Η @KissAcademy έκανε εξαιρετική δουλειά και μου έδωσε όλες τις απαραίτητες βάσεις για να ξεκινήσω την καριέρα μου ως developer!',
    framework: 'Full-Stack Engineer',
    date:"Ιούνιος 2019"
  },
];