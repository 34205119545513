import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

// Centralized way of setting and updating meta tags application wide
export class SeoService {
  constructor(private title: Title, private meta: Meta) {}

  // Dynamically update the title of each new page component
  public updateTitle(title: string) {
    this.title.setTitle(title);
  }

  /* 
  Dynamically update the robots meta tag of each new page component.
  Since we want all of our pages to be crawlable by bots, this method
  is not really needed. In the future if we want to specify non craw-
  -lable pages, this can be the place to do so. 
  */
  public updateRobots(url: string) {
    this.meta.updateTag({ name: 'robots', content: url });
  }

  // Dynamically update the description of each new page component
  public updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }
}
