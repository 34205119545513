export interface LearningPath {
  name: string;
  text: string;
  path: 'basis' | 'development' | 'framework';
}

export interface COURSES {
  title: string;
  duration: number;
  description: string;
}

export const LEARNING_PATHS: LearningPath[] = [
  {
    name: 'Θεμέλια',
    text: 'Ξεκίνησε από την αρχή',
    path: 'basis',
  },
  {
    name: 'Development',
    text: 'Αναβάθμισε τις γνώσεις σου',
    path: 'development',
  },
  {
    name: 'Framework',
    text: 'Μπες στην πρώτη γραμμή',
    path: 'framework',
  },
];

export const COURSE_TYPE_JR_FRONT_END: COURSES= {
 title: 'Jr. Front End Developer σε',
 duration: 8,
 description: 'Το Πρόγραμμα Εκπαίδευσης “Rookie Front End Developer” αποτελείται από τρείς ολοκληρωμένους κύκλους μαθημάτων που θα σε μετατρέψουν σε Jr. Front End Developer έτοιμο για δράση στην αγορά εργασίας.'
};
export const COURSE_TYPE_JR_ANGULAR: COURSES = {
  title: 'Mastering Angular σε',
  duration: 3,
  description:
    'Τo Πρόγραμμα Εκπαίδευσης “Mastering Angular" θα σε εκτοξεύσει στους καλύτερους Angular Developers.',
};
export const COURSE_TYPE_AI: COURSES= {
 title: 'Μάθε AI',
 duration: 6,
 description: 'Το Πρόγραμμα Εκπαίδευσης “Artificial Inteligence” θα ....'
};

export const COURSE_TYPE_JR_TEST_AUTOMATION: COURSES= {
 title: 'Jr. Test Automation Engineer σε',
 duration: 4,
 description: 'Το Πρόγραμμα Εκπαίδευσης “Test Automation Journey” αποτελείται από έναν ολοκληρωμένο κύκλο μαθημάτων που θα σε μετατρέψουν σε Jr. Test Automation Engineer έτοιμο για δράση στην αγορά εργασίας. '
};

export const COURSE_TYPE_JR_QA: COURSES= {
 title: 'Jr. QA Engineer σε',
 duration: 2,
 description: 'Το Πρόγραμμα Εκπαίδευσης “Software Testing Journey” αποτελείται από έναν ολοκληρωμένο κύκλο μαθημάτων που θα σε μετατρέψουν σε Jr. QA Engineer έτοιμο για δράση στην αγορά εργασίας.'
};
