<footer>
  <section class="footer-wrapper container">
    <span>KissMyButton Academy &copy; 2021</span>
    <nav>
      <ul>
        <li (click)="goTop()">
          <a routerLink="/terms">Όροι Χρήσης</a>
        </li>
      </ul>
    </nav>
    <section class="social-media-icons">
      <a href="https://kissmybutton.gr/" target="_blank" rel="noreferrer">
        Project of Kiss My Button
      </a>
      <a href="https://www.instagram.com/kissmybutton_academy/" target="_blank" rel="noreferrer" aria-label="instagram">
        <fa-icon [icon]="faInstagram"></fa-icon>
      </a>
      <a href="https://www.facebook.com/profile.php?id=100083342740566" target="_blank" rel="noreferrer" aria-label="facebook">
        <fa-icon [icon]="faFacebook"></fa-icon>
      </a>
    </section>
  </section>
</footer>