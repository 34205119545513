import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormspreeFormServiceService {

  //Formspree API
 private formUrl = 'https://formspree.io/f/xpznzknr';
 private headers = new HttpHeaders({'content-type': 'application/json'})
 constructor(private httpClient: HttpClient) { }
 
 /**
  * Post method to send the form data to the email we set in formspree
  * @param contact form data to be process
  */
 sendEmail(contact: any): Observable<any>{
   return this.httpClient.post<any>(
     this.formUrl,  
   {//Post body
    firstName: contact.firstName,
    lastName: contact.lastName,
     email: contact.email,
     message: contact.message,
     phonenumber: contact.phonenumber,
     course: contact.course
   },
   {
     headers: this.headers
   }
   ).pipe(
     tap(() => console.warn('sending message')),
     catchError(this.handleError<any>('sendEmail', []))
   );
 }
 
 /**
  * Requests error handler
  * @param operation Type of operation we are executing
  * @param result what exactly happen after the execution
  */
 private handleError<T>(operation = 'operation', result?: T){
   return(error: any): Observable<T> =>{
     //We can stream this log to an platform like CloudWatch
     console.error(error);
 
     //Let our app keep running
     return of(result as T);
   }
 }
}
