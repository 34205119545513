import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  AbstractControl,
} from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ApplyModalService } from '@shared/services/apply-modal/apply-modal.service';
import { Feedback } from './feedback.model';
import { finalize } from 'rxjs/operators';
import { FormspreeFormServiceService } from './formspree-form-service/formspree-form-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

declare let gtag_report_conversion: Function;
declare let submitConversion: Function;
declare let fbq: Function;
@Component({
  selector: 'app-apply-modal',
  templateUrl: './apply-modal.component.html',
  styleUrls: ['./apply-modal.component.scss'],
})
export class ApplyModalComponent implements OnInit {
  applyForm!: UntypedFormGroup;
  faTimes = faTimes;
  submitBtnPressed = false;

  constructor(
    public applyModalService: ApplyModalService,
    private fb: UntypedFormBuilder,
    private contactService: FormspreeFormServiceService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  onSubmit() {
    this.submitBtnPressed = true;
    if (this.applyForm.invalid) return;

    const data = {
      firstName: this.applyForm.get('firstName')?.value,
      lastName: this.applyForm.get('lastName')?.value,
      email: this.applyForm.get('email')?.value,
      phonenumber: this.applyForm.get('phonenumber')?.value,
      course: this.applyForm.get('course')?.value,
    };

    const entry = { ...data } as Feedback;

    this.contactService
      .sendEmail(entry)
      .pipe(
        finalize(() => {
          this.applyForm.reset();
          this.applyModalService.onClose();
        })
      )
      .subscribe((res) => {
        if (res.ok) {
          gtag_report_conversion();
          submitConversion();
          fbq('track', 'Contact');
          this._snackBar.open(
            'Τα στοιχεία σας έχουν καταχωρηθεί επιτυχώς!',
            'Κλείσιμο',
            {
              duration: 3000,
              panelClass: ['mat-toolbar', 'success-snackbar'],
              verticalPosition: 'top',
              horizontalPosition: 'center',
            }
          );
        } else {
          this._snackBar.open(res, 'Κλείσιμο', {
            duration: 3000,
            panelClass: ['mat-toolbar', 'error-snackbar'],
            verticalPosition: 'top',
            horizontalPosition: 'center',
          });
        }
      });
  }

  private initializeForm() {
    this.applyForm = this.fb.group({
      firstName: this.fb.control('', [
        Validators.required,
        this.validateIsOneWord.bind(this),
      ]),
      lastName: this.fb.control('', [
        Validators.required,
        this.validateIsOneWord.bind(this),
      ]),
      email: this.fb.control('', [Validators.email, Validators.required]),
      phonenumber: this.fb.control('', [Validators.required]),
      // course: 'jrFrontEnd',
      course: this.fb.control('', [Validators.required]),
    });
  }

  validateIsOneWord(control: AbstractControl): any {
    if (control.value) {
      const eachCharacter = control.value.split('');
      const isOneWord = control.value.split(' ').length === 1;
      const isNotNumber = !eachCharacter.some((char: any) => !isNaN(char));

      return isOneWord && isNotNumber ? null : { notOneWordAndNaN: true };
    }
  }

  get firstName() {
    return this.applyForm.get('firstName') as UntypedFormControl;
  }

  get lastName() {
    return this.applyForm.get('lastName') as UntypedFormControl;
  }

  get email() {
    return this.applyForm.get('email') as UntypedFormControl;
  }

  get course() {
    return this.applyForm.get('course') as UntypedFormControl;
  }

  get phonenumber() {
    return this.applyForm.get('phonenumber') as UntypedFormControl;
  }
}
