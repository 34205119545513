import { Component, OnInit } from '@angular/core';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { ApplyModalService } from '@shared/services/apply-modal/apply-modal.service';
import { EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  faAngleUp = faAngleUp;
  showApplyModal: Observable<boolean> = EMPTY;

  constructor(private applyModalService: ApplyModalService) {}

  ngOnInit(): void {
    this.showApplyModal = this.applyModalService.visibilityHandler;
  }

  scrollTopSmoothly() {
    document.querySelector('body')?.scrollIntoView({ behavior: 'smooth' });
  }
}