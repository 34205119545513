import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { HomeComponent } from './pages/home/home.component';
import { IntroComponent } from './components/intro/intro.component';
import { DontWasteTimeComponent } from './components/dont-waste-time/dont-waste-time.component';
import { SectionComponent } from './components/section/section.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';

@NgModule({
  declarations: [
    HomeComponent,
    IntroComponent,
    DontWasteTimeComponent,
    SectionComponent,
    NewsletterComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([{ path: '', component: HomeComponent }]),
  ],
  exports: [HomeComponent, RouterModule],
})
export class HomeModule {}