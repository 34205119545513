<article class="graduate-container">
  <picture>
    <source type="image/webp" [srcset]="graduate.webpImagePath">
    <img [src]="graduate.pngImagePath" alt="{{ graduate.name }} logo" />
  </picture>
  <span class="graduate-name">{{ graduate.name }}</span>
  <div class="quote-container">
    <div class="blockquote">{{graduate.comments}}</div>
  </div>
  <section class="graduate-info">
    <span class="graduate-position">{{ graduate.framework}}
    </span>
    <span>{{ graduate.date }}</span>
  </section>
</article>