<section class="flex-container container">
  <div class="img-container">
    <picture>
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 1139px)"
        srcset="assets/images/home/dont_waste_time_320.webp"
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 1139px)"
        srcset="assets/images/home/dont_waste_time_320.png"
      />
      <source
        type="image/webp"
        media="(min-width: 1140px)"
        srcset="assets/images/home/dont_waste_time_450.webp"
      />
      <source
        type="image/webp"
        media="(min-width: 1140px)"
        srcset="assets/images/home/dont_waste_time_450.png"
      />
      <img
        src="assets/images/home/dont_waste_time_320.png"
        class="responsive-img"
        width="320"
        height="203"
        style="width: initial; height: initial"
        alt="Don't waste time"
      />
    </picture>
  </div>
  <section class="dont-waste-time">
    <h2>Μην σπαταλάς τον πολύτιμο σου χρόνο και χρήματα</h2>
    <p>
      Ανακάλυψε τον ταχύτερο και πιο αποτελεσματικό τρόπο για να αποκτήσεις
      job-ready εμπειρία.
    </p>
    <button
      class="primary-btn"
      (click)="applyModalService.onOpen()"
      aria-label="Express interest"
    >
      Ενημερώσου Δωρεάν
    </button>
  </section>
</section>
