<section class="intro-container container space">
  <h1 class="header-center">
    Ξεκίνα την καινούργια σου καριέρα στο Web Development
  </h1>
  <span class="span-center">Εκπαίδευση, πρακτική και γράφεις κώδικα!</span>
  <div class="intro-image">
    <picture>
      <source type="image/webp"
        media="(max-width: 767px)"
        srcset="assets/images/home/academy_screen_350.webp">
      <source
        media="(max-width: 767px)" srcset="assets/images/home/academy_screen_350.png">
      <source type="image/webp"
        media="(min-width: 768px) and (max-width: 1279px)"
        srcset="assets/images/home/academy_screen_600.webp">
      <source
        media="(min-width: 768px) and (max-width: 1279px)" srcset="assets/images/home/academy_screen_600.png" >
      <source type="image/webp"
        media="(min-width: 1280px)"
        srcset="assets/images/home/academy_screen_800.webp">
      <img src="assets/images/home/academy_screen_600.png" class="responsive-img" alt="Laptop" width="600" height= "388" style="width: initial; height: initial;"/>
    </picture>
  </div>
</section>
