<section class="learning-paths">
  <article *ngFor="let learningPath of learningPaths" class="learning-path">
    <button class="primary-btn" aria-label="Course">
      <a routerLink="/junior-front-end-developer/{{learningPath.path}}" (click)="goTop()">
        {{ learningPath.name }}
      </a>
    </button>
    <p>{{ learningPath.text }}</p>
  </article>
</section>
