import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplyModalService {
  visibilityHandler = new BehaviorSubject(false);

  constructor() { }

  onOpen() {
    this.visibilityHandler.next(true);
    document.body.style.overflow = "hidden";
  }

  onClose() {
    this.visibilityHandler.next(false);
    document.body.style.overflow = "auto";
  }
}