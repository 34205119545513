import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appShowOnScroll]',
})
export class ShowOnScrollDirective {
  constructor(private renderer: Renderer2, private elRef: ElementRef) {}

  @HostListener('window:scroll') onScroll() {
    if (window.scrollY > 100) {
      this.renderer.addClass(this.elRef.nativeElement, 'show');
    } else {
      this.renderer.removeClass(this.elRef.nativeElement, 'show');
    }
  }
}
