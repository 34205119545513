import { Section } from '../models/section.model';

export const STUDY_FROM_EVERYWHERE: Section = {
  title: 'Σπούδασε από όποιο μέρος νιώθεις άνετα',
  content:
    'Τα μαθήματα είναι 100% remote, όπως πολύ πιθανό να είναι και κάποιες από τις επόμενες δουλειές σου. Η επικοινωνία και ο συντονισμός της ομάδας των μαθητών γίνεται μέσα από τα ακριβώς ίδια μέσα & εργαλεία συνεργασίας που χρησιμοποιούν οι σύγχρονες εταιρείες ανάπτυξης λογισμικού για τον συντονισμό remote ομάδων ώστε να αισθάνεσαι “στο σπίτι” όταν θα έρθει η πρώτη σου remote δουλειά!',
  image230Path: 'assets/images/home/study_from_everywhere_230.png',
  image320Path: 'assets/images/home/study_from_everywhere_320.png',
  webpImage230Path: 'assets/images/home/study_from_everywhere_230.webp',
  webpImage320Path: 'assets/images/home/study_from_everywhere_320.webp',
};

export const LEARN_FROM_THE_BEST: Section = {
  title: 'Μάθε δίπλα στους καλύτερους',
  content:
    'Τα μαθήματα γίνονται με τη βοήθεια και την καθημερινή επίβλεψη από Lead & Sr. Μηχανικούς, που κατέχουν πολυετή εμπειρία σε κορυφαίου επιπέδου projects διεθνώς. Εκμεταλλεύσου την ευκαιρία, μοιράσου την εμπειρία από τη μελέτη σου και απηύθυνε τις ερωτήσεις σου σε Engineers που μπορούν να σου δείξουν το επόμενο βήμα στην διαδρομή σου προς την τελειοποίηση του τρόπου σκέψης και γραφής του κώδικα σου.',
  image320Path: 'assets/images/home/learn_from_the_best_lessons_320.png',
  image450Path: 'assets/images/home/learn_from_the_best_lessons_450.png',
  webpImage320Path: 'assets/images/home/learn_from_the_best_lessons_320.webp',
  webpImage450Path: 'assets/images/home/learn_from_the_best_lessons_450.webp',
};

export const GET_CERTIFIED: Section = {
  title: 'Πιστοποιήσου',
  content:
    'Η διαδρομή δεν θα είναι εύκολη, θέλει προσπάθεια, αγάπη για το αντικείμενο και επιμονή. Φτάνοντας στο τέλος, η Kiss My Button με υπερηφάνεια θα πιστοποιήσει γραπτώς ότι κατέκτησες τον τίτλο του Jr. Front End Developer των πολύ υψηλών standards που η ίδια οριοθετεί.',
  image320Path: 'assets/images/home/get_certified_320.jpg',
  image450Path: 'assets/images/home/get_certified_450.jpg',
  webpImage320Path: 'assets/images/home/get_certified_320.webp',
  webpImage450Path: 'assets/images/home/get_certified_450.webp',
};