import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  /*
  A directive used in the mobile navigation menu. It determines if a click event came from the button that opens the menu or outside of it. Based on that the menu closes if the click was made on the document and no the element itself.
  */
  @Output() public clickedOutside: EventEmitter<boolean> = new EventEmitter();
  @HostListener('document:click', ['$event.target'])
  public onClick(target: any) {
    const clickedInside = this.elRef.nativeElement.contains(target);
    clickedInside
      ? this.clickedOutside.emit(false)
      : this.clickedOutside.emit(true);
  }
  constructor(private elRef: ElementRef) {}
}
