<app-intro></app-intro>

<courses-duration></courses-duration>

<app-section
  [section]="studyFromEverywhere"
  [isStudyFromEverywhere]="isStudyFromEverywhere"
  class="study-from-everywhere"
></app-section>
<!-- <app-newsletter></app-newsletter> -->
<app-section
  [section]="learnFromTheBest"
  [imageOrder]="1"
  class="learn-from-best"
></app-section>
<!-- <app-study-topics></app-study-topics> -->
<app-section [section]="getCertified"
class="get-certified"></app-section>
<app-graduates></app-graduates>
<app-dont-waste-time></app-dont-waste-time>

<app-apply-modal *ngIf="showApplyModal"></app-apply-modal>
