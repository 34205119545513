<header>
  <app-header></app-header>
</header>
<main>
  <router-outlet></router-outlet>
  <button (click)="scrollTopSmoothly()" appShowOnScroll aria-label="Top arrow leads to the upper part of the page">
    <fa-icon [icon]="faAngleUp"></fa-icon>
  </button>
</main>
<footer>
  <app-footer></app-footer>
</footer>

<app-apply-modal *ngIf="showApplyModal | async"></app-apply-modal>