import { Component, Input, OnInit } from '@angular/core';
import { Section } from '../../models/section.model';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit {
  @Input() section = {} as Section;
  @Input() imageOrder = 0;
  @Input() isStudyFromEverywhere = false;

  constructor() {}

  ngOnInit(): void {}
}
