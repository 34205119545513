import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { COURSES, COURSE_TYPE_JR_FRONT_END, COURSE_TYPE_JR_QA, COURSE_TYPE_JR_TEST_AUTOMATION, COURSE_TYPE_JR_ANGULAR, LearningPath, LEARNING_PATHS } from '../../learning-path';
import { Subject } from 'rxjs';
import { ApplyModalService } from '@shared/services/apply-modal/apply-modal.service';

@Component({
  selector: 'courses-duration',
  templateUrl: './courses-duration.component.html',
  styleUrls: ['./courses-duration.component.scss'],
})
export class CoursesDurationComponent implements OnInit {
  unsubscribe$ = new Subject;
  learningPaths: LearningPath[] = LEARNING_PATHS;
  isFrontEnd = false;
  currentRoute = '';
  course: COURSES;

  constructor(
    private router: Router,public applyModalService: ApplyModalService
    ) {
      this.currentRoute = this.router.url;
       this.course = COURSE_TYPE_JR_FRONT_END;
       if (this.currentRoute.includes('junior-front-end-developer')) {
        this.isFrontEnd = true;
       }
       if (this.currentRoute.includes('angular-framework')) {
        this.course = COURSE_TYPE_JR_ANGULAR;
       }
       if (this.currentRoute.includes('qa-engineer')) {
        this.course = COURSE_TYPE_JR_QA;
      } else if (this.currentRoute.includes('test-automation-engineer')) {
        this.course = COURSE_TYPE_JR_TEST_AUTOMATION;
      }
    }

  ngOnInit(): void {
  }
}
