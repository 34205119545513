<div class="header-container">
  <div class="container visible-nav">
    <a routerLink="/home" class="brand">
      <img
        src="assets/images/kissmybutton_logo1.png"
        alt="Kiss My Button logo"
      />
    </a>
    <div>
      <button
        class="burger-menu"
        mat-button
        disableRipple
        [matMenuTriggerFor]="burgerMenu"
        (click)="showBurgerMenu = !showBurgerMenu"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <ul>
      <li class="dropdown">
        <button
          class="expand-more-btn"
          appClickOutside
          (click)="handleClickOutsideCourse($event)"
          (clickedOutside)="handleClickOutsideCourse($event)"
        >
          <span>Bootcamps</span>
          <mat-icon
            [ngClass]="{
              'rotate-down': showHiddenNav,
              'rotate-up': !showHiddenNav
            }"
          >
            expand_more
          </mat-icon>
        </button>
        <ul
          class="dropdown-content"
          [ngClass]="{ visible: showHiddenNav, hidden: !showHiddenNav }"
        >
          <li>
            <a
              routerLink="/junior-front-end-developer/intro"
              routerLinkActive="active-link"
              >Jr. Front End Developer</a
            >
          </li>
          <li>
            <a
              routerLink="/angular-framework/intro"
              routerLinkActive="active-link"
              >Mastering Angular</a
            >
          </li>
          <!-- <li>
            <a
              routerLink="/ai/intro"
              routerLinkActive="active-link"
              >Artificial Inteligence</a
            >
          </li> -->
          <!-- <li>
            <a routerLink="/qa-engineer/intro" routerLinkActive="active-link">Jr. QA Engineer</a>
          </li>
          <li>
            <a routerLink="/test-automation-engineer/intro" routerLinkActive="active-link">Jr. Test Automation Engineer</a>
          </li> -->
        </ul>
      </li>

      <!-- <li>
        <a routerLink="/junior-front-end-developer/intro" routerLinkActive="active-link"
          >Jr. Front End Developer</a
        >
      </li> -->
      <li>
        <a routerLink="/methodology" routerLinkActive="active-link"
          >Μεθοδολογία</a
        >
      </li>
      <li>
        <a routerLink="/life-choice" routerLinkActive="active-link"
          >Επιλογή ζωής</a
        >
      </li>
      <li>
        <a routerLink="/contact" routerLinkActive="active-link">Επικοινωνία</a>
      </li>
    </ul>
    <button
      class="primary-btn interest-btn"
      (click)="applyModalService.onOpen()"
      aria-label="Open form of interest"
    >
      Ενημερώσου Δωρεάν
    </button>
  </div>

  <mat-menu #courses="matMenu" yPosition="below" id="courses">
    <button mat-menu-item>
      <a
        routerLink="/junior-front-end-developer/intro"
        routerLinkActive="active-link"
        >Jr. Front End Developer</a
      >
    </button>
    <button mat-menu-item>
      <a routerLink="/angular-framework/intro" routerLinkActive="active-link"
        >Mastering Angular</a
      >
    </button>
    <!-- <button mat-menu-item>
      <a
        routerLink="/ai/intro"
        routerLinkActive="active-link">Artificial Inteligence</a
      >
    </button> -->
    <!-- <button mat-menu-item>
      <a routerLink="/qa-engineer/intro" routerLinkActive="active-link"
        >Jr. QA Engineer</a
      >
    </button>
    <button mat-menu-item>
      <a
        routerLink="/test-automation-engineer/intro"
        routerLinkActive="active-link"
        >Jr. Test Automation Engineer</a
      >
    </button> -->
  </mat-menu>

  <mat-menu #burgerMenu="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="courses">
      <mat-icon> expand_more </mat-icon>
      <a routerLinkActive="active-link">Bootcamps</a>
    </button>
    <!-- <button mat-menu-item>
      <a routerLink="/junior-front-end-developer/intro" routerLinkActive="active-link"
        >Jr. Front End Developer</a
      >
    </button> -->
    <button mat-menu-item>
      <a routerLink="/methodology" routerLinkActive="active-link"
        >Μεθοδολογία</a
      >
    </button>
    <button mat-menu-item>
      <a routerLink="/life-choice" routerLinkActive="active-link"
        >Επιλογή ζωής</a
      >
    </button>
    <button mat-menu-item>
      <a routerLink="/contact" routerLinkActive="active-link">Επικοινωνία</a>
    </button>
    <button
      mat-menu-item
      class="primary-btn interest-btn"
      (click)="applyModalService.onOpen()"
      aria-label="Open form of interest"
    >
      Ενημερώσου Δωρεάν
    </button>
  </mat-menu>

  <hr />
</div>
