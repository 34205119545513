import { Component, Input, OnInit } from '@angular/core';
import { Graduate } from '../../graduates';

@Component({
  selector: 'app-graduate',
  templateUrl: './graduate.component.html',
  styleUrls: ['./graduate.component.scss'],
})
export class GraduateComponent implements OnInit {
  commentsWithQuotes = "";
  @Input() graduate = {} as Graduate;

  constructor() {}

  ngOnChanges(val: any) {
    if(val.graduate.currentValue.comments) {
      this.commentsWithQuotes ="";
      this.commentsWithQuotes = '"'+ val.graduate.currentValue.comments +'"'
    }
  }

  ngOnInit(): void {
  }
}
