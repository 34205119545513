import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HeaderComponent } from './components/navigation/components/header/header.component';
import { FooterComponent } from './components/navigation/components/footer/footer.component';
import { ShowOnScrollDirective } from './components/navigation/directives/show-on-scroll/show-on-scroll.directive';
import { CourseStructureComponent } from './components/course-structure/course-structure.component';
import { CourseIntroComponent } from './components/course-intro/course-intro.component';
import { ApplyModalComponent } from './components/apply-modal/apply-modal.component';
import { EllipsisModule } from 'ngx-ellipsis';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClickOutsideDirective } from './components/navigation/directives/click-outside/click-outside.directive';
import { LearningPathsComponent } from './components/learning-paths/components/learning-paths/learning-paths.component';
import { StudyTopicsComponent } from './components/study-topics/components/study-topics/study-topics.component';
import { StudyTopicComponent } from './components/study-topics/components/study-topic/study-topic.component';
import { QaEngineerDurationComponent } from './components/qa-engineer-duration/qa-engineer-duration.component';
import { AngularFrameworkDurationComponent } from './components/angular-framework-duration/angular-framework-duration.component';
import { AIDurationComponent } from './components/ai-duration/ai-duration.component';
import { MatMenuModule } from '@angular/material/menu';
import { CoursesDurationComponent } from './components/learning-paths/components/courses-duration/courses-duration.component';
import { GraduateComponent } from './components/graduates/components/graduate/graduate.component';
import { GraduatesComponent } from './components/graduates/components/graduates/graduates.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    ShowOnScrollDirective,
    CourseStructureComponent,
    CourseIntroComponent,
    ApplyModalComponent,
    ClickOutsideDirective,
    CoursesDurationComponent,
    LearningPathsComponent,
    StudyTopicComponent,
    StudyTopicsComponent,
    QaEngineerDurationComponent,
    AngularFrameworkDurationComponent,
    AIDurationComponent,
    GraduateComponent,
    GraduatesComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatMenuModule,
  ],
  exports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NavigationComponent,
    CourseStructureComponent,
    CourseIntroComponent,
    ApplyModalComponent,
    EllipsisModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    CoursesDurationComponent,
    LearningPathsComponent,
    StudyTopicComponent,
    StudyTopicsComponent,
    QaEngineerDurationComponent,
    AngularFrameworkDurationComponent,
    AIDurationComponent,
    GraduateComponent,
    GraduatesComponent
  ],
})
export class SharedModule {}
