import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { Graduate, GRADUATES } from '../../graduates';

@Component({
  selector: 'app-graduates',
  templateUrl: './graduates.component.html',
  styleUrls: ['./graduates.component.scss'],
})
export class GraduatesComponent implements OnInit, OnDestroy {
  @HostListener('window:resize')
  onResize() {
    this.initializeEndIndex();
    this.startIndex = 0;
  }
  graduates: Graduate[] = [];
  isLoading = false;
  currentIndex = 0;
  startIndex = 0;
  endIndex!: number;
  private readonly destroy$ = new Subject<null>();

  constructor() {}

  ngOnInit(): void {
    this.graduates = GRADUATES;

    this.initializeEndIndex();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  matchMedia(media: string) {
    return window.matchMedia(media).matches;
  }

  topicsArrOfSpecificRange(startIndex: number, endIndex: number) {
    return this.graduates.slice(startIndex, endIndex);
  }

  private initializeEndIndex() {
    if(this.matchMedia('(max-width: 767px)')) {
      this.endIndex = 1;
    } else if(this.matchMedia('(min-width: 768px) and (max-width: 959px)')) {
      this.endIndex = 2;
    } else if(this.matchMedia('(min-width: 960px)')) {
      this.endIndex = 3;
    }
  }
}