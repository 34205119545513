import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { STUDY_FROM_EVERYWHERE, LEARN_FROM_THE_BEST, GET_CERTIFIED } from '../../mock/home-sections.mock';
import { Section } from '../../models/section.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  showApplyModal = false;
  studyFromEverywhere = {} as Section;
  learnFromTheBest = {} as Section;
  getCertified = {} as Section;
  isStudyFromEverywhere = true;
  currentRoute: string;

  constructor( private router: Router) {
    this.currentRoute = this.router.url;
    this.getCertified = GET_CERTIFIED;

    this.learnFromTheBest = LEARN_FROM_THE_BEST;
    
  }

  ngOnInit(): void {
    this.studyFromEverywhere = STUDY_FROM_EVERYWHERE;
  }
}
