import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SeoService } from '@shared/services/seo/seo.service';
import { filter, map, mergeMap } from 'rxjs/operators';

// declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router, 
    private activatedRoute : ActivatedRoute, 
    private seoService : SeoService
    ) {}

  ngOnInit() {
    // Here we set the meta tags wherever there is a navigation event
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute), 
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        } 
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
     )
     .subscribe((event) => {
       this.seoService.updateTitle(event['title']);
       this.seoService.updateRobots(event['robots']);
       this.seoService.updateDescription(event['description'])
     }); 
  }
}
