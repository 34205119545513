<div class="apply-background"></div>
<section class="apply-wrapper" (click)="applyModalService.onClose()">
  <section class="apply-container" (click)="$event.stopPropagation()">
    <button mat-icon-button aria-label="Exit" class="exit-btn" aria-label="Exit">
      <fa-icon [icon]="faTimes" (click)="applyModalService.onClose()"></fa-icon>
    </button>
    <h2 class="apply-text">Μην διστάσεις να επικοινωνήσεις μαζί μας και σύντομα ένας από τους υπεύθυνους της Ακαδημίας
      θα έρθει σε επαφή
      μαζί σου για να συζητήσουμε τη συμμετοχή σου στο πρόγραμμα που θα σου αλλάξει την επαγγελματική ζωή.</h2>
    <form name="apply" [formGroup]="applyForm" (ngSubmit)="onSubmit()">
      <div class="form-field-container">
        <label class="interest-form-label" for="interestFirstName"> Name </label>
        <input type="text" name="firstName" id="interestFirstName" placeholder="Όνομα" formControlName="firstName" />
        <span class="error-message" *ngIf="submitBtnPressed && firstName.errors?.required">Το όνομα είναι
          απαραίτητο</span>
        <span class="error-message" *ngIf="submitBtnPressed && firstName.errors?.notOneWordAndNaN">Το όνομα είναι μη
          έγκυρο</span>
      </div>
      <div class="form-field-container">
        <label class="interest-form-label" for="interestLastName">Surname</label>
        <input type="text" name="lastName" placeholder="Επώνυμο" id="interestLastName" formControlName="lastName" />
        <span class="error-message" *ngIf="submitBtnPressed && lastName.errors?.required">Το επώνυμο είναι
          απαραίτητο</span>
        <span class="error-message" *ngIf="submitBtnPressed && lastName.errors?.notOneWordAndNaN">Το επώνυμο είναι μη
          έγκυρο</span>
      </div>
      <div class="form-field-container">
        <label class="interest-form-label" for="interestEmail">Email</label>
        <input type="email" name="email" placeholder="Διεύθυνση ηλεκτρονικού ταχυδρομείου" id="interestEmail"
          formControlName="email" />
        <span class="error-message" *ngIf="submitBtnPressed && email.errors?.required">Η διεύθυνση ηλεκτρονικού
          ταχυδρομείου είναι απαραίτητη</span>
        <span class="error-message" *ngIf="submitBtnPressed && email.errors?.email">Η διεύθυνση ηλεκτρονικού
          ταχυδρομείου είναι μη έγκυρη</span>
      </div>
      <div class="form-field-container">
        <label class="interest-form-label" for="interestPhonenumber"> Phone Number</label>
        <input class="phone-number" type="tel" name="phonenumber" placeholder="Τηλέφωνο επικοινωνίας"
          id="interestPhonenumber" formControlName="phonenumber" />
          <span class="error-message" *ngIf="submitBtnPressed && phonenumber.errors?.required">Το τηλέφωνο επικοινωνίας είναι απαραίτητo</span>
      </div>
      <div class="form-field-container">
        <label class="interest-form-label" for="interestCourse"> Course of interest</label>
        <select id="interestCourse" name="interestCourse" class="course" [ngStyle]="{'color':course.value? 'black' : 'gray', 'background':course.value? 'rgb(232, 240, 254)' : '#e9e9ec' }" formControlName="course">
          <option value="" disabled selected class="placeholder-text">   Μάθημα ενδιαφέροντος</option>
          <option value="jrFrontEnd" class="option">Jr. Front End Developer</option>
          <option value="angularFr" class="option">Mastering Angular</option>
          <!-- <option value="ai" class="option">Artificial Inteligence</option> 
          <option value="jrQaEngineer">Jr. QA Engineer</option>
          <option value="jrTestAutomation">Jr. Test Automation Engineer</option> -->
        </select>
        <mat-icon>
          expand_more
        </mat-icon>
        <span class="error-message" *ngIf="submitBtnPressed && course.errors?.required">Το μάθημα ενδιαφέροντος είναι απαραίτητο.</span>
      </div>
      <p class="terms-text">Με την υποβολή της φόρμας αποδέχεσαι ότι η KissMyButton Academy θα αποθηκεύσει τα στοιχεία σου για περαιτέρω επικοινωνία. Σε κάθε περίπτωση μπορείς να τα διαγράψεις στέλνοντας email με το αίτημα σου στο academy@kissmybutton.gr.</p>
      <button type="submit" id="submit-btn" class="primary-btn" aria-label="Submit form of interest">Υποβολή</button>
    </form>
  </section>
</section>