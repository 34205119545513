import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplyModalService } from '@shared/services/apply-modal/apply-modal.service';

@Component({
  selector: 'app-dont-waste-time',
  templateUrl: './dont-waste-time.component.html',
  styleUrls: ['./dont-waste-time.component.scss']
})
export class DontWasteTimeComponent implements OnInit {

  constructor(public router: Router, public applyModalService: ApplyModalService) { }

  ngOnInit(): void {}

  goTop() {
    window.scrollTo(0,0);
  }
}