<section class="graduates-container">
  <h2>Τι λένε οι απόφοιτοι της Ακαδημίας</h2>
  <section class="graduates-wrapper">
    <section class="graduates-wrapper-content">
      <ng-container *ngIf="matchMedia('(max-width: 767px)')">
        <button class="arrow" [disabled]="currentIndex === 0" (click)="currentIndex = currentIndex - 1"
          aria-label="Arrow back">
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <section class="graduates">
          <app-graduate [graduate]="graduates[currentIndex]"></app-graduate>
        </section>
        <button class="arrow" [disabled]="currentIndex === graduates.length - 1"
          (click)="currentIndex = currentIndex + 1" aria-label="Arrow next">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </ng-container>

      <ng-container *ngIf="matchMedia('(min-width: 768px) and (max-width: 959px)')">
        <button class="arrow" [disabled]="startIndex === 0"
          (click)="startIndex = startIndex - 2; endIndex = endIndex - 2" aria-label="Arrow back">
          &#10096;
        </button>
        <section class="graduates">
          <app-graduate *ngFor="
          let graduate of topicsArrOfSpecificRange(startIndex, endIndex)
          " [graduate]="graduate"></app-graduate>
        </section>
        <button class="arrow" [disabled]="endIndex > graduates.length - 1"
          (click)="startIndex = startIndex + 2; endIndex = endIndex + 2" aria-label="Arrow next">
          &#10097;
        </button>
      </ng-container>

      <ng-container *ngIf="matchMedia('(min-width: 960px)')">
        <button class="arrow" [disabled]="startIndex === 0"
          (click)="startIndex = startIndex - 3; endIndex = endIndex - 3" aria-label="Arrow back">
          &#10096;
        </button>
        <section class="graduates">
          <app-graduate *ngFor="
          let graduate of topicsArrOfSpecificRange(startIndex, endIndex)
          " [graduate]="graduate"></app-graduate>
        </section>
        <button class="arrow" [disabled]="endIndex > graduates.length - 1"
          (click)="startIndex = startIndex + 3; endIndex = endIndex + 3" aria-label="Arrow next">
          &#10097;
        </button>
      </ng-container>
    </section>
  </section>
</section>